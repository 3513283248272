import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ArticleTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const author = data.allAuthorsJson.edges[0].node
  const avatar = data.allAvatar.edges[0].node

  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={image}
      />
      <nav className="mb-4">
        <Link to={`/`} class="block">
          ← All articles
        </Link>
      </nav>
      <article>
        <header className="mb-4">
          <h1>{post.frontmatter.title}</h1>
          <ul className="post-details">
            <li>{post.frontmatter.date}</li>
            <li>{post.timeToRead} min read</li>
          </ul>
        </header>
        <section
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr className="mb-5" />
        <footer>
          <Bio author={author} avatar={avatar} />
        </footer>
      </article>

      <nav className="my-4">
        <Link to={`/`} class="block">
          ← All articles
        </Link>
      </nav>
    </Layout>
  )
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug(
    $slug: String!
    $authorShortName: String!
    $authorAvatarRegex: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allAuthorsJson(filter: { shortName: { eq: $authorShortName } }) {
      edges {
        node {
          shortName
          name
          summary
          social {
            github
            twitter
            linkedin
          }
        }
      }
    }
    allAvatar: allFile(
      filter: {
        absolutePath: { regex: $authorAvatarRegex }
        sourceInstanceName: { eq: "assets" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image: image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
